/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Libraries
import parse from 'html-react-parser'

// Language
import { getLanguage } from 'services/language'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import NationsSeeker from 'components/NationsSeeker'

// Elements
import ImageBackground from 'components/elements/ImageBackground'
import HeroAlt from 'components/elements/HeroAlt'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'
import { USP } from 'components/Identity'
import BackgroundDescription from 'components/elements/BackgroundDescription'
import ButtonSecondary from 'components/elements/ButtonSecondary'
import ButtonAnimateTruck from 'components/elements/ButtonAnimateTruck'
import PriceIndication from 'components/PriceIndication'
import RatingAlt from 'components/Reviews/RatingAlt'
import Team from 'components/Team'

import TitleDefault from 'components/elements/TitleDefault'
import Logo from 'img/logo-wit.svg'

// Data
import homeInfo from 'src/graphql/homeInfo'

const Intro = styled(BlockDefault)`
  @media(min-width: 992px) {
    margin-top: -200px;

    & h2 {
      font-size: ${props => props.theme.font.size['50']};
      margin-bottom: 2rem;
    }
  }

  @media(max-width: 991px) {
    margin-top: -100px;
  }

  
`

const BackgroundImageBackground = styled(ImageBackground)`
  position: absolute !important;
  height: 950px;
  width: 1600px;
  top: 0;
  right: 0;
  z-index: 0;
`

const Department = styled.h2`
  font-size: ${props => props.theme.font.size['50']};
  font-weight: ${props => props.theme.font.weight.xl};
  color: ${props => props.theme.color.text.contrast};

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  height: 50px;
  line-height: 20px;

  @media (max-width: 991px) {
    line-height: 25px;
  
    > a {
      font-size: 14px;
    }
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          intro
          usp {
            title
            description
          }
          outro
          background_description
          background_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }

        team {
          management_team
          sales_team
          move_management_team
          whs_and_planning_team
          finance_team
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`


const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const [indexOpen, setIndexOpen] = useState(null)

  const setOpen = index => {
    setIndexOpen(index === indexOpen ? null : index)
  }

  const homeFields = homeInfo.get()

  const language = getLanguage()


  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroAlt small image={acf.banner.image}>
          <HeroDescription className="d-flex align-items-center justify-content-start h-100">
            {parse(acf.banner.title)}
          </HeroDescription>
        </HeroAlt>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 position-relative">
              <BackgroundImageBackground style={{ backgroundSize: 'contain', backgroundPosition: 'right center' }} src={acf.meta.background_image} />
            </div>
          </div>
        </div>

        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-5" />
            <div className="col-lg-7">
              <Intro bigBorderRadius>
                <ParseContent className="py-lg-4 px-lg-4" content={acf.meta.intro} />
              </Intro>
            </div>
          </div>
        </div>

        <div className="container mb-5 pb-5">
          <div className="row pt-lg-5 mb-lg-5">
            <div className="col-lg-6" />
            <div className="col-lg-5 mt-lg-4">
              {acf.meta.usp.map(({ title, description }, index) => (
                <USP
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  title={title}
                  description={description}
                  index={index}
                  setOpen={setOpen}
                  indexOpen={indexOpen}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mb-lg-5 pb-lg-5 d-lg-block d-none">
          <BackgroundDescription className="mt-3 py-lg-5" content={acf.meta.background_description} />
        </div>
      </section>

      <section>
        <div className="container">
          <div className="col-lg-8">
            {language === 'nl_NL' ? (
              <Department className="mb-4">Onze werkwijze</Department>
            ) : (
              <Department className="mb-4">Our way of working</Department>
            )}
            <video width="100%" height="auto" controls poster="https://admin.schmidt-global.nl/wp-content/uploads/2023/07/1697607519-81317f26f6f6e3d6b29baa89bb01790432d01873259ad576ed4e30ba707225e4-d.jpg">
              <source src="https://player.vimeo.com/progressive_redirect/playback/844200796/rendition/1080p/file.mp4?loc=external&signature=96335263736cf783c1f404e2ecaa938690e423ae3773378a4368174582adfa2c" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5 py-5">
          <div className="row py-5">
            <div className="col-12 d-flex align-items-center">
              <Department>Management</Department>
            </div>
            <Team employeeIds={acf.team.management_team} />
          </div>


          <div className="row py-5">
            <div className="col-12 d-flex flex-wrap align-items-center">
              <Department>Sales</Department>
              {language === 'nl_NL' ? (
                <>
                  <StyledButtonSecondary to='/contact' className="mx-lg-4 my-3 my-lg-0">
                    Neem contact op
                  </StyledButtonSecondary>
                  <ButtonAnimateTruck to='/offerte' big>
                    ik wil een prijsindicatie
                  </ButtonAnimateTruck>
                </>
              ) : (
                <>
                  <StyledButtonSecondary to='/en/contact' className="mx-lg-4 my-3 my-lg-0">
                    Contact us
                  </StyledButtonSecondary>
                  <ButtonAnimateTruck to='/en/quotation' big>
                    I want a price indication
                  </ButtonAnimateTruck>
                </>
              )}
            </div>
            <Team employeeIds={acf.team.sales_team} />
          </div>

          <div className="row py-5">
            <div className="col-12 d-flex align-items-center">
              <Department>Move Management</Department>
            </div>
            <Team employeeIds={acf.team.move_management_team} />
          </div>

          <div className="row py-5">
            <div className="col-12 d-flex flex-wrap align-items-center">
              <Department>WHS and Planning</Department>
              {language === 'nl_NL' ? (
                <StyledButtonSecondary to='/contact' className="mx-lg-4 mt-3 mt-lg-0">
                  Ik heb een vraag over mijn lopende verhuizing
                </StyledButtonSecondary>
              ) : (
                <StyledButtonSecondary to='/en/contact' className="mx-lg-4 mt-3 mt-lg-0">
                  I have a question about my current move
                </StyledButtonSecondary>
              )}

            </div>
            <Team employeeIds={acf.team.whs_and_planning_team} />
          </div>

          <div className="row py-5">
            <div className="col-12 d-flex flex-wrap align-items-center">
              <Department>Finance</Department>
              {language === 'nl_NL' ? (
                <StyledButtonSecondary to='/mailto:invoice@schmidtglobal.nl' className="mx-lg-4 mt-3 mt-lg-0">
                  Ik heb een vraag over mijn factuur
                </StyledButtonSecondary>
              ) : (
                <StyledButtonSecondary to='/en/contact' className="mx-lg-4 mt-3 mt-lg-0">
                  I have a question about my invoice
                </StyledButtonSecondary>
              )}
            </div>
            <Team employeeIds={acf.team.finance_team} />
          </div>


          <div className="row my-5">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <BlockDefault>
                <ParseContent content={acf.meta.outro} />
              </BlockDefault>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container mb-lg-5 py-5">
          <div className="row">
            <div className="col-lg-7" />
            <div className="col-lg-5">
              <RatingAlt noTitle className="pb-lg-0 pb-4" />
              <div className="mt-4">
                <PriceIndication />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="container mt-5 py-5">
          <NationsSeeker fields={homeFields.acf.search} showSirelo />
        </div>
      </section>
    </Layout>
  )
}

const EmployeeBlock = styled.div`
  background-color: ${props => props.theme.color.contrast};
  border-radius: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);
`

const EmployeeDescription = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.m};

  & h1, & h2, & h3 {
    font-weight: ${props => props.theme.font.weight.s};

    & strong {
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }

  & h2 {
    font-size: ${props => props.theme.font.size.l};
  }

  & p {
    text-align: left;
    line-height: 23px;
    
    & strong {
      font-weight: ${props => props.theme.font.weight['700']};
    }
  }
`

const EmployeeImage = styled(ImageBackground)`
  height: 250px;
  width: 200px;
  margin-top: -50px;
`

const AlterImg = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 991px){
    width: 150px;
  }
`

const Employee = ({ data }) => (
  <EmployeeBlock className="w-100">
    <>
      {data.image ? (
        <div className="d-flex align-items-start justify-content-end">
          <EmployeeImage src={data.image} style={{ backgroundSize: 'contain' }} />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <div style={{ height: '200px' }} className="d-flex flex-column justify-content-center">
            <AlterImg src={Logo} />
          </div>
        </div>
      )}
    </>
    <div className="p-4">
      <EmployeeDescription content={data.name} />
    </div>
  </EmployeeBlock>
)

export default PageTemplate
